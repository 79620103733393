import { Link } from "gatsby"
import React from "react"
import { ButtonLinkInt } from "../Button"
import { SubTitle } from "../SiteComponents"

const PastEventCard = ({ slug, title, summary = null, index = 0 }) => {
  const cardColors = [
    {
      color: "green",
    },
    {
      color: "blue",
    },

    {
      color: "red",
    },
    {
      color: "black",
    },
  ]

  const colors = cardColors[index % 4]
  const theColor = cardColors[index % cardColors.length].color

  return (
    <div className="grid mb-20 border rounded-lg pt-5 pb-10 px-5 shadow-lg  ">
      <Link to={`/past-events/${slug}`}>
        {/* <pre>{JSON.stringify(colors, null, 2)}</pre> */}
        <SubTitle
          className={` ${
            theColor === "green"
              ? "text-nofnecGreen"
              : theColor === "red"
              ? "text-nofnecRed"
              : theColor === "blue"
              ? "text-blue-500"
              : theColor === "black"
              ? "text-gray-900"
              : "text-black"
          } text-6xl`}
        >
          {title}
        </SubTitle>
      </Link>
      {/* Event Summary Info */}
      {summary && <p className="my-5 text-2xl md:text-3xl">{summary}</p>}

      <ButtonLinkInt
        to={`/past-events/${slug}`}
        className={`pt-3 pb-2 px-5 justify-self-center ${
          theColor === "green"
            ? "bg-nofnecGreen"
            : theColor === "red"
            ? "bg-nofnecRed"
            : theColor === "blue"
            ? "bg-blue-500"
            : theColor === "black"
            ? "bg-gray-900"
            : "bg-black"
        } text-white hover:bg-white ${
          theColor === "green"
            ? "hover:text-nofnecGreen"
            : theColor === "red"
            ? "hover:text-nofnecRed"
            : theColor === "blue"
            ? "hover:text-blue-500"
            : theColor === "black"
            ? "hover:text-gray-900"
            : "hover:text-black"
        } hover:underline text-2xl rounded text-center mt-10`}
        text={`More about ${title}`}
      />
    </div>
  )
}

export default PastEventCard
