import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { mySanityClient } from "../sanityClient"
import workshop from "../../../studio/schemas/documents/workshop"
import TitleBox from "../components/titleBox"
import SEO from "../components/SEO"
import {
  H2,
  PageContainer,
  PageTitle,
  SubTitle,
} from "../components/SiteComponents"
import BlockContentPrimary from "../components/BlockContentPrimary"
import { ButtonLinkInt } from "../components/Button"
import Layout from "../components/layout"
import PastEventCard from "../components/pastEvents/PastEventCard"

// const getWorkshops = (client, query) => {
//   client.fetch(query).then(workshops => {

//     return "hello"
//     // return workshops.map(workshop => ({ title: workshop.title }))
//   })
// }

// https://sanity-io-land.slack.com/archives/C9Z7RC3V1/p1612089489493700?thread_ts=1612082379.490300&cid=C9Z7RC3V1

const PastEvents = ({ data }) => {
  const [eventData, setEventData] = useState([])
  const [loading, setLoading] = useState(true)

  const { file, sanityPastEventPage } = data
  const { title } = sanityPastEventPage
  const { edges: pastEvents } = data.allSanityPastEvent
  const client = mySanityClient
  const fluid = sanityPastEventPage?.headerImage?.asset?.fluid || null

  const { _rawDescription: description } = sanityPastEventPage
  // Use effect fetches data on the client side.  To fetch GROQ data on the server side, consider this article:
  // https://www.gatsbyjs.com/docs/how-to/querying-data/using-gatsby-without-graphql/
  // useEffect(() => {
  //   const query =
  //     '*[_type == "pastEvent"]{ title, "workshops":workshops[]->{title, summary}|order(title asc) }'
  //   console.log(query)
  //   client.fetch(query).then(data => {
  //     console.log("RESPONSE", data)
  //     setEventData(data)
  //     setLoading(false)
  //   })
  // }, [])

  return (
    <Layout>
      <SEO title={title} />
      <TitleBox title={title} background={fluid}></TitleBox>
      <PageContainer className="bg-white gap-y-10">
        {pastEvents.length > 0 ? (
          <>
            {/* Page Summay Info Description */}
            <div className="md:w-5/6">
              <BlockContentPrimary blockData={description} />
            </div>

            {/* Past events */}
            <div className=" md:w-5/6 lg:w-4/6">
              {pastEvents.map(({ node: event }, index) => {
                const {
                  summary,
                  title,
                  slug: { current: slug },
                } = event

                return (
                  <PastEventCard
                    slug={slug}
                    title={title}
                    summary={summary}
                    index={index}
                    key={`${index}-past-event-card`}
                  />
                )
              })}
            </div>

            {/* {loading && <img src={file.publicURL} />}
      <div>
        {eventData.map(event => (
          <>
            <h2>{event.title}</h2>
            {event.workshops.map(workshop => (
              <>
                <p>{workshop.title}</p>
                <p className="ml-5">{workshop.summary}</p>
              </>
            ))}
          </>
        ))}
      </div> */}
          </>
        ) : (
          <PageTitle>
            No events posted yet.
            <br /> Please check again soon!
          </PageTitle>
        )}
      </PageContainer>
    </Layout>
  )
}

export const query = graphql`
  query PastEventsQuery {
    allSanityPastEvent(
      sort: { fields: title, order: DESC }
      filter: { isPastEvent: { eq: true } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          summary
        }
      }
    }
    file(relativePath: { eq: "loading.gif" }) {
      publicURL
    }
    sanityPastEventPage {
      title
      headerImage {
        asset {
          fluid(maxWidth: 1800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawDescription(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default PastEvents
