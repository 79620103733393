import React from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import { PageTitle } from "./SiteComponents"

const TitleBox = ({
  background = null,
  text = "",
  title = "",
  children,
  className,
}) => {
  const data = useStaticQuery(graphql`
    query TitleBoxQuery {
      defaultBackground: file(relativePath: { eq: "lakePlaceholder.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const backgroundImage =
    background || data.defaultBackground.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="div"
      className={`titleBox__title-background ${className} flex`}
      fluid={backgroundImage}
    >
      <div className="titleBox__container pt-32 md:pt-20 pb-10 flex flex-col flex-grow">
        <div className="titleBox__container-welcome w-11/12  md:w-3/4 text-2xl">
          <PageTitle className="mb-10 text-center text-white">
            {title}
          </PageTitle>
          {text ? <p>{text}</p> : null}
          {children}
        </div>
      </div>
    </BackgroundImage>
  )
}

export default TitleBox
